import React from 'react'

import Footer from './Footer'
import avatar from '../assets/images/avatar.jpg'

const Header = ({ location, lang }) => (
  <header id="header">
    <div className="inner">
      <a href="/" className="image avatar">
        <img src={avatar} alt="" />
      </a>
      <h1>
        <strong>{(lang === "default" || lang === "ca") ? 'Benvingut/da!' : 'Welcome'}</strong>
        <br />
        {(lang === "default" || lang === "ca") ? 'Soc la' : 'I am'} <strong>Sara Cillan</strong>,
        <br />
        {(lang === "default" || lang === "ca") ? 'Musicoterapeuta i Educadora infantil' : 'Music therapist and childhood educator'}
      </h1>
    </div>
    <Footer />
  </header>
)

export default Header
